/* Custom styling for the carousel dots */
.ant-carousel .slick-dots li button {
  background: #cccccc; /* Color of non-active dots */
}

.ant-carousel .slick-dots li.slick-active button {
  background: #152c5b; /* Color of the active dot */
}

/* Optional: if you want to change the size of the dots */
.ant-carousel .slick-dots li button {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  border: 2px solid #152c5b;
}

/* Optional: if you want to change the spacing between the dots */
.ant-carousel .slick-dots li {
  margin: 0 4px;
}
:where(.css-dev-only-do-not-override-1rqnfsa).ant-carousel .slick-dots-bottom {
  bottom: -18px;
}
:where(.css-1rqnfsa).ant-carousel .slick-dots-bottom {
  bottom: -18px;
}

.slick-slide {
  opacity: 0.3;
}
.inputStyle::placeholder {
  color: rgba(21, 44, 91, 0.5);

  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.slick-slide.slick-current {
  opacity: 1;
}
.nav-button {
  color: black;
  background: transparent;
  margin: 0px 10px 0px 10px;
}
.nav-button :hover {
  color: cadetblue;
  background: transparent !important;
}
:where(.css-dev-only-do-not-override-1rqnfsa).ant-btn-text:not(:disabled):not(.ant-btn-disabled):hover {
  color: black;
  background: none;
}
.common-button {
  @apply !text-white !bg-[#fe7a36];
}
.common-button:hover {
  color: black !important;
  background-color: #ffd700 !important;
}
.common-button-1 {
  color: white;
  background-color: #152c5b;
}
.common-button-3 {
  color: white;
  background-color: #152c5b;
  border: 1px solid white;
}

.common-button-1:hover {
  color: white !important;
  background-color: #314ed9 !important;
}
.common-button-3:hover {
  color: white !important;
  background-color: #314ed9 !important;
}

.readyTogoHeader {
  font-family: Poppins;
  font-size: 44px;
  font-weight: 900;
  line-height: 66px;
  letter-spacing: 0em;
  text-align: left;
}
.readyTogoHeaderFuture {
  font-family: Poppins;
  font-size: 44px;
  font-weight: 900;
  line-height: 66px;
  letter-spacing: 0em;
  text-align: end;
}
.readyTogoDes {
  font-family: Poppins;
  font-size: 24px;
  font-weight: 700;
  line-height: 33px;
  letter-spacing: 0em;
  text-align: left;
}

.readyTogoText {
  font-family: Poppins;
  font-size: 18px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: left;
}
.readyTogoTextFuture {
  font-family: Poppins;
  font-size: 18px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: right;
}
.futureIsHereText {
  font-family: Poppins;
  /* font-size: 32px; */
  font-weight: 700;
  line-height: 48px;
  letter-spacing: 0em;
  text-align: center;
  color: #314ed9;
}
.ourCoreStrengthTitle {
  font-family: Poppins;
  font-size: 44px;
  font-weight: bolder;
  line-height: 66px;
  letter-spacing: 0em;
  text-align: left;
}

.ourCoreStrengthText {
  font-family: Poppins;
  font-size: 18px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: left;
}
.ourCoreStrengthButton {
  font-family: Poppins;
  font-size: 44px;
  font-weight: 900;
  line-height: 66px;
  letter-spacing: 0em;
  text-align: left;
  width: 300px;
  height: 110px;
  padding-top: 19px !important;
  padding-left: 40px !important;
  padding-bottom: 19px !important;
  padding-right: 40px !important;
  border-radius: 27px;
  color: #152c5b;
  background: #eaebee;
}

.ourCoreStrengthButton:hover {
  color: white !important;
  background-color: #314ed9 !important;
}

.ourCoreStrengthsButtonText {
  font-family: Poppins;
  font-size: 24px;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: left;
}
.digitalFutureSmallTitle {
  font-family: Poppins;
  font-size: 18px;
  font-weight: 700;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: left;
}
.digitalFutureTitle {
  font-family: Poppins;
  font-size: 44px;
  font-weight: 900;
  line-height: 66px;
  letter-spacing: 0em;
  text-align: left;
}
.digitalFutureText {
  font-family: Poppins;
  font-size: 18px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: left;
}

.techStackTitle {
  font-family: Poppins;
  font-size: 32px;
  font-weight: 700;
  line-height: 48px;
  letter-spacing: 0em;
  text-align: center;
}
.techStackText {
  font-family: Poppins;
  font-size: 18px;
  font-weight: 700;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: center;
}
.techStackBelowTitle {
  font-family: Poppins;
  font-size: 24px;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: left;
}
.techStackBelowText {
  font-family: Poppins;
  font-size: 18px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: left;
}
.techStackPoint {
  font-family: Poppins;
  font-size: 18px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0em;
}
.common-button-2 {
  color: white;
  background: #228b22 !important;
}
.common-button-2:hover {
  color: black;
  background: #50e850 !important;
}
.bigEmpireTitle {
  font-family: Poppins;
  font-size: 44px;
  font-weight: 400;
  line-height: 66px;
  letter-spacing: 0em;
  text-align: left;
}
.bigEmpireText {
  font-family: Poppins;
  font-size: 18px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: left;
}
.bigEmpireFooter {
  color: #152c5b;
  font-family: Poppins;
  font-size: 24px;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: left;
}
.ourCustomerLove {
  font-family: Poppins;
  color: #152c5b;
  font-size: 44px;
  font-weight: 900;
  line-height: 66px;
  letter-spacing: 0em;
  text-align: left;
}
.ourCustomerCardTitle {
  font-family: Poppins;
  color: #152c5b;
  font-size: 24px;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: left;
}
.ourCustomerCardSubtitle {
  font-family: Poppins;
  color: #152c5b;
  font-size: 18px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: left;
}
.ourCustomerText {
  color: #333333;
  font-family: Poppins;
  font-size: 18px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: left;
}

.slick-dots li button {
  background: white;
}

.slick-dots li button:hover {
  background: #152c5b;
}

/* Active state of the dots */
.slick-dots li.slick-active button {
  background: #152c5b; /* Replace with your custom active color */
}
:where(.css-dev-only-do-not-override-12upa3x).ant-carousel .slick-dots li button {
  position: relative;
  display: block;
  width: 8px;
  height: 8px;
  padding: 0;
  color: transparent;
  font-size: 0;
  background: red;
  border: 0;
  border-radius: 50%;
  outline: none;
  cursor: pointer;
  opacity: 0.3;
  transition: all 0.3s;
}
.footerText {
  color: #ffffff;
  font-family: Poppins;
  font-size: 18px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: left;
}
.footerEmail {
  color: #ffffff;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
}
.footerAddressTitle {
  color: #ffffff;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
}
.footerAddressText {
  color: #ffffff;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
}
.footerCompanyHeader {
  color: #ffffff;
  font-family: Poppins;
  font-size: 25px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
}
.footerCompanyText {
  color: #ffffff;
  font-family: Poppins;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
}
.footerCopyRight {
  color: #ffffff;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
}
.yourInnovationPartnerTitle {
  color: #ffffff;
  font-family: Poppins;
  font-size: 96px;
  font-weight: 900;
  line-height: 144px;
  letter-spacing: 0em;
  text-align: left;
  width: 80%;
  padding-top: 10%;
}
.yourInnovationPartnerText {
  color: #ffffff;
  font-family: Poppins;
  font-size: 18px;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;
  width: 60%;
  padding-bottom: 10%;
}
.whoAreWeTitle {
  color: #152c5b;
  font-family: Poppins;
  font-size: 44px;
  font-weight: 900;
  line-height: 66px;
  letter-spacing: 0em;
  text-align: left;
}
.whoAreWeText {
  color: #333333;
  font-family: Poppins;
  font-size: 18px;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;
  width: 50%;
}
.aboutUsTechText {
  color: #ffffff;
  font-family: Poppins;
  font-size: 18px;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: center;
}
.ourVisionContainer {
  display: flex;
  justify-content: space-between;
}
.ourVisionHeader {
  color: #152c5b;
  font-family: Poppins;
  font-size: 44px;
  font-weight: 900;
  line-height: 66px;
  letter-spacing: 0em;
  text-align: left;
}
.ourVisionText {
  color: #333333;
  font-family: Poppins;
  font-size: 18px;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;
}
.ourVisionImageContainer {
  width: 40%;
}
.ourVisionTextContainer {
  width: 60%;
}
.whatOurClientSayTitle {
  color: #152c5b;
  font-family: Poppins;
  font-size: 44px;
  font-weight: 900;
  line-height: 66px;
  letter-spacing: 0em;
  text-align: left;
}
.whatOurClientSayMainContainer {
  display: flex;
  justify-content: space-between;
}
.whatOurCustomerCardTitle {
  color: #152c5b;
  font-family: Poppins;
  font-size: 24px;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: left;
}
.whatOurCustomerCardSubTitle {
  color: #152c5b;
  font-family: Poppins;
  font-size: 18px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: left;
}
.whatOurCustomerCardSubText {
  color: #333333;
  font-family: Poppins;
  font-size: 18px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: left;
}
.whatWeDoTitle {
  color: #ffffff;
  font-family: Poppins;
  font-size: 44px;
  font-weight: 900;
  line-height: 66px;
  letter-spacing: 0em;
  text-align: left;
}
.whatWeDoSubtitle {
  color: #ffffff;
  font-family: Poppins;
  font-size: 24px;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: left;
}
.whatWeDoButton {
  color: #fe7a36;
  font-family: Poppins;
  font-size: 24px;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: left;
}
.contact-us-title {
  color: #333333;
  font-family: Poppins;
  font-size: 64px;
  font-weight: 600;
  line-height: 96px;
  letter-spacing: 0em;
  text-align: left;
}
.contact-us-dot-1 {
  height: 40px;
  width: 40px;
  background-color: #152c5b;
  border-radius: 50%;
}
.contact-us-dot-2 {
  height: 40px;
  width: 40px;
  background-color: #314ed9;
  border-radius: 50%;
}
.contact-us-information-container {
  width: 100%;
  height: 100%;
  background-color: #152c5b;
  border-radius: 20px;
}
.contact-us-heading {
  color: #ffffff;
  font-family: Poppins;
  font-size: 32px;
  font-weight: 700;
  line-height: 48px;
  letter-spacing: 0em;
  text-align: left;
}
.contact-us-address-title {
  color: #ffffff;
  font-family: Poppins;
  font-size: 18px;
  font-weight: 700;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: left;
}
.contact-us-address-text {
  color: #ffffff;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
}
.contact-us-follow-us {
  color: #ffffff;
  font-family: Poppins;
  font-size: 24px;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: left;
}
.contact-us-below-banner-title {
  color: #ffffff;
  font-family: Poppins;
  font-size: 44px;
  font-weight: 900;
  line-height: 66px;
  letter-spacing: 0em;
  text-align: left;
}
.contact-us-below-subtitle {
  color: #ffffff;
  font-family: Poppins;
  font-size: 18px;
  font-weight: 700;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: left;
}
.contact-us-form-title {
  color: #314ed9;
  font-family: Poppins;
  font-size: 32px;
  font-weight: 700;
  line-height: 48px;
  letter-spacing: 0em;
  text-align: left;
}
.contact-us-form-subtitle {
  color: #152c5b;
  font-family: Poppins;
  font-size: 18px;
  font-weight: 700;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: left;
}
.contact-us-form-below-text {
  color: #333333;
  font-family: Poppins;
  font-size: 18px;
  font-weight: 700;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: left;
}
.contact-us-i-agree {
  color: #000000;
  font-family: Poppins;
  font-size: 18px;
  font-weight: 700;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: left;
}
.contact-us-form-main-container {
  display: flex;
  align-items: center;
}
.contact-us-email-container {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 14px 0px 0px 14px;
  background-color: #152c5b;
  height: 550px;
  width: 100px;
  border: 0px;
}
.contact-us-form-input-container {
  width: 30%;
  height: 850px;
  background: white;
  border-radius: 20px;
}
.contact-us-form-map-container {
  width: 62%;
  height: 550px;
  background-color: white;
  /* box-shadow: 1px 1px 8px 1px rgba(0, 0, 0, 0.66); */
  border-radius: 0px 20px 20px 0px;
}
.service-header-title {
  color: #152c5b;
  font-family: Poppins;
  font-size: 44px;
  font-weight: 900;
  line-height: 66px;
  letter-spacing: 0em;
  text-align: center;
}
.service-header-text {
  color: #333333;
  font-family: Poppins;
  font-size: 18px;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: center;
}
.evolving-header {
  color: #314ed9;
  font-family: Poppins;
  font-size: 32px;
  font-weight: 700;
  line-height: 48px;
  letter-spacing: 0em;
  text-align: left;
}
.evolving-text {
  color: #000000;
  font-family: Poppins;
  font-size: 18px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: left;
}
.transforming-header {
  color: #000000;
  font-family: Poppins;
  font-size: 32px;
  font-weight: 700;
  line-height: 48px;
  letter-spacing: 0em;
  text-align: left;
}
.transforming-title {
  color: #000000;
  font-family: Poppins;
  font-size: 28px;
  font-weight: 700;
  line-height: 42px;
  letter-spacing: 0em;
  text-align: left;
}
.transforming-text {
  color: #000000;
  font-family: Poppins;
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;
}
.service-tech-stack-title {
  color: #152c5b;
  font-family: Poppins;
  font-size: 24px;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: center;
}
.service-tech-stack-text {
  color: #333333;
  font-family: Poppins;
  font-size: 18px;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: center;
}
.faq-header {
  color: #152c5b;
  font-family: Poppins;
  font-size: 32px;
  font-weight: 700;
  line-height: 48px;
  letter-spacing: 0em;
  text-align: center;
}
.collapseTitle {
  color: #333333;
  font-family: Poppins;
  font-size: 24px;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;
}
.bannerImageReadyToGo {
  width: 100%;
}
.readySetCodeMainContainer {
  display: flex;
  justify-content: space-between;
}
.digitalFutureContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.digitalFutureImageBox {
  width: 100%;
}
.digitalFutureTextBox {
  width: 45%;
}
.techStackContentBox {
  height: auto;
}
.techStackBelowBox {
  display: flex;
  justify-content: flex-start;
  align-content: center;
}
.bigEmpireContainer {
  display: flex;
  align-items: center;
}
.footerMainContainer {
  display: flex;
  justify-content: space-between;
}
.footerBelowContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.footerTextContainer {
  width: 30%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.footerAddressContainer {
  width: 30%;
}
.footerCompanyContainer {
  width: 30%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.footerSocialMediaCOntainer {
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  width: 9%;
}
.whatOurClientSayHeaderContainer {
  display: flex;
  flex-direction: column;
  width: 50%;
}
.whatOurClientSayCarouselContainer {
  width: 50%;
}
.whatWeDoMainContainer {
  display: flex;
}
.contactUsBelowBannerFirstComponent {
  display: flex;
  justify-content: space-between;
}
.contactUsBelowBannerSecondComponent {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}
.evolving-tech-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.evolving-tech-text-container {
  width: 45%;
}
.evolving-img-container {
  width: 45%;
}
.transforming-main-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.transforming-img-container {
  width: 45%;
}
.transforming-text-container {
  width: 45%;
}
.service-tech-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 313px;
  border: 1px solid #152c5b;
  border-radius: 15px;
}
.your-career-tech-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 300px;
  background-color: white;
  border-radius: 15px;
}
.your-career-title {
  color: #333333;
  font-family: Poppins;
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: center;
}
.your-career-text {
  color: #333333;
  font-family: Poppins;
  font-size: 18px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: center;
}
.your-career-tech-text-title-1 {
  color: #152c5b;
  font-family: Poppins;
  font-size: 44px;
  font-weight: 900;
  line-height: 66px;
  letter-spacing: 0em;
  text-align: center;
}
.your-career-tech-text-title-2 {
  color: #314ed9;
  font-family: Poppins;
  font-size: 44px;
  font-weight: 900;
  line-height: 66px;
  letter-spacing: 0em;
  text-align: center;
}
.your-career-tech-text-per {
  color: #333333;
  font-family: Poppins;
  font-size: 18px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: left;
}
.our-recruitment-header {
  color: #152c5b;
  font-family: Poppins;
  font-size: 32px;
  font-weight: 700;
  line-height: 48px;
  letter-spacing: 0em;
  text-align: center;
}
.blog-banner-text {
  color: #ffffff;
  font-family: Poppins;
  font-size: 64px;
  font-weight: 900;
  letter-spacing: 0em;
  text-align: left;
}
.blogs-tag {
  color: #333333;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.blog-title {
  color: #152c5b;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.blog-des {
  color: #333333;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.blog-date {
  color: #333333;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.team-name-title {
  color: #152c5b;
  text-align: center;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.team-position-title {
  color: #333333;
  text-align: center;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px; /* 155.556% */
}
.team-header {
  color: #333333;
  font-family: Poppins;
  font-size: 44px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
}
.driveYourTechHeader {
  color: white;
  font-family: Poppins;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.driveYourTechText {
  color: white;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px; /* 155.556% */
}
.privacy-policy {
  font-family: Poppins;
  max-width: 800px;
  margin: 20px auto;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.privacy-policy h1,
.privacy-policy h2 {
  color: #333;
}

.privacy-policy p {
  text-align: justify;
  line-height: 1.6;
}

.privacy-policy strong {
  color: #000;
}
@media (max-width: 768px) {
  .readySetCodeMainContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .ourCoreStrengthButton {
    font-family: Poppins;
    font-size: 44px;
    font-weight: 900;
    line-height: 66px;
    letter-spacing: 0em;
    text-align: left;
    width: 300px;
    height: 110px;
    padding-top: 19px !important;
    padding-left: 40px !important;
    padding-bottom: 19px !important;
    padding-right: 40px !important;
    border-radius: 27px;

    background: #eaebee;
  }
  .bannerImageReadyToGo {
    width: 320px;
  }
  .readyTogoHeader {
    font-family: Poppins;
    font-size: 40px;
    font-weight: 900;
    line-height: 66px;
    letter-spacing: 0em;
    text-align: center;
  }
  .readyTogoHeaderFuture {
    font-family: Poppins;
    font-size: 40px;
    font-weight: 900;
    line-height: 66px;
    letter-spacing: 0em;
    text-align: center;
  }
  .digitalFutureContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
  .digitalFutureImageBox {
    width: 80%;
  }
  .digitalFutureTextBox {
    width: 100%;
  }
  .techStackContentBox {
    height: auto;
  }
  .techStackBelowBox {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-content: center;
  }
  .techStackPoint {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 400;
    line-height: 12px;
    letter-spacing: 0em;
  }
  .bigEmpireContainer {
    display: flex;
    flex-direction: column;
  }
  .footerMainContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  /* .footerBelowContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  } */
  .footerTextContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .footerAddressContainer {
    width: 100%;
  }
  .footerCompanyContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .footerSocialMediaCOntainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 30%;
    margin-top: 10px;
  }
  .yourInnovationPartnerTitle {
    color: #ffffff;
    font-family: Poppins;
    font-size: 50px;
    font-weight: 900;
    line-height: 50px;
    letter-spacing: 0em;
    text-align: left;
    width: 100%;
    padding-top: 10%;
  }
  .yourInnovationPartnerText {
    color: #ffffff;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: left;
    width: 100%;
    padding-bottom: 10%;
  }
  .whoAreWeText {
    color: #333333;
    font-family: Poppins;
    font-size: 18px;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: left;
    width: 100%;
  }
  .ourVisionContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .ourVisionImageContainer {
    width: 100%;
  }
  .ourVisionTextContainer {
    width: 100%;
  }
  .whatOurClientSayMainContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .whatOurClientSayHeaderContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .whatOurClientSayCarouselContainer {
    width: 100%;
  }
  .whatWeDoMainContainer {
    display: flex;
    flex-direction: column;
  }
  .contactUsBelowBannerFirstComponent {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .contactUsBelowBannerFirstComponentSecondImage {
    margin-top: 10px;
  }
  .contactUsBelowBannerSecondComponent {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 10px;
  }
  .contact-us-title {
    color: #333333;
    font-family: Poppins;
    font-size: 48px;
    font-weight: 600;
    line-height: 96px;
    letter-spacing: 0em;
    text-align: left;
  }
  .contact-us-dot-1 {
    height: 30px;
    width: 30px;
    background-color: #152c5b;
    border-radius: 50%;
  }
  .contact-us-dot-2 {
    height: 30px;
    width: 30px;
    background-color: #314ed9;
    border-radius: 50%;
  }
  .contact-us-information-container {
    width: 100%;
    height: 100%;
    background-color: #152c5b;
    border-radius: 20px;
  }
  .contact-us-follow-us {
    color: #ffffff;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: left;
  }
  .contact-us-address-title {
    color: #ffffff;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 700;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
  }
  .contact-us-address-text {
    color: #ffffff;
    font-family: Poppins;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
  }
  .contact-us-below-banner-title {
    color: #ffffff;
    font-family: Poppins;
    font-size: 30px;
    font-weight: 900;
    line-height: 40px;
    letter-spacing: 0em;
    text-align: left;
  }
  .contact-us-below-subtitle {
    color: #ffffff;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: left;
  }
  .contactUsBelowBannerSecondSecondImage {
    margin-top: 10px;
  }
  .contactUsBelowBannerSecondThirdImage {
    margin-top: 10px;
  }
  .contact-us-form-main-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .contact-us-email-container {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 14px 14px 0px 0px;
    background-color: #152c5b;
    height: 80px;
    width: 80%;
    border: 0px;
  }
  .contact-us-form-input-container {
    width: 100%;
    height: 850px;
    background: white;
    border-radius: 20px;
  }
  .contact-us-form-map-container {
    width: 90%;
    height: 450px;
    background-color: white;
    box-shadow: 0px 8px 8px 8px rgba(0, 0, 0, 0.66);
    border-radius: 0px 0px 20px 20px;
  }
  .evolving-tech-container {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    align-items: center;
  }
  .evolving-tech-text-container {
    margin-top: 20px;
    width: 100%;
  }
  .evolving-header {
    color: #314ed9;
    font-family: Poppins;
    font-size: 24px;
    font-weight: 700;
    line-height: 38px;
    letter-spacing: 0em;
    text-align: left;
  }
  .evolving-img-container {
    width: 100%;
  }
  .transforming-main-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
  .transforming-img-container {
    width: 100%;
  }
  .transforming-text-container {
    margin-top: 20px;
    width: 100%;
  }
  .button-container {
    display: flex;
    justify-content: center;
  }
  .service-header-title {
    color: #152c5b;
    font-family: Poppins;
    font-size: 24px;
    font-weight: 900;
    line-height: 40px;
    letter-spacing: 0em;
    text-align: center;
  }
  .service-header-text {
    color: #333333;
    font-family: Poppins;
    font-size: 12px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: center;
  }
  .service-tech-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 280px;
    border: 1px solid #152c5b;
    border-radius: 15px;
  }
  .collapseTitle {
    color: #333333;
    font-family: Poppins;
    font-size: 12px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
  }
}

@media only screen and (max-width: 574px) {
  .bannerImageReadyToGo {
    width: 300px !important;
  }
  .service-tech-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 280px;
    border: 1px solid #152c5b;
    border-radius: 15px;
  }
}
@media (max-width: 687px) {
  .service-tech-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 280px;
    border: 1px solid #152c5b;
    border-radius: 15px;
  }
  .footerSocialMediaCOntainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 70%;
    margin-top: 10px;
  }
}
@media (max-width: 300px) {
}
.my-carousel .slick-dots {
  text-align: center; /* Center align the dots */
  li button:before {
    color: rgba(0, 0, 0, 0.75) !important; /* Change the color to something visible */
  }
}

.carousel-card {
  padding: 16px; /* Adjust padding as needed */
}

.card-content {
  display: flex;
  align-items: center;
  gap: 16px; /* Adjust the gap as needed */
}

.text-content {
  text-align: center;
}
.navbar-collapse {
  padding-left: 35px;
}
.careerImg {
  background-image: url('../src/Images/carrer_1.png');
}
@media screen and (min-width: 0px) and (max-width: 450px) {
  .home-bg,
  .digitalFutureTextBox,
  .contact-bg {
    background-image: none !important;
  }
}
@media screen and (min-width: 0px) and (max-width: 450px) {
  .careerImg {
    background-image: none !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.btn-check:active + .btn-primary,
.btn-check:checked + .btn-primary,
.btn-primary.active,
.btn-primary:active,
.show > .btn-primary.dropdown-toggle {
  border-color: #f3f7ff;
  background-color: #f3f7ff;
  color: #152c5b;
}
.btn-primary:hover {
  background-color: #f3f7ff;
  border-color: #fff;
  color: #152c5b;
}
.btn-check:focus + .btn-primary,
.btn-primary:focus {
  border: 0;
  outline: 0;
}
.btn-check:active + .btn-primary:focus,
.btn-check:checked + .btn-primary:focus,
.btn-primary.active:focus,
.btn-primary:active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: none;
  background-color: #f3f7ff;
  color: #152c5b;
}
.btn-primary {
  border-color: #fff;
}
.dropdown-toggle::after {
  margin-left: 1em;
}
.map-bg {
  background-image: url('./Images/Map_BG.png');
}

/* Hide image on small screens */
.responsive-image {
  display: none;
}

/* Show image on medium screens (768px) and larger */
@media (min-width: 768px) {
  .responsive-image {
    display: block;
  }
}

.navServiceDropdown {
  @apply cursor-pointer text-[#0000008c] font-bold px-4 py-2  hover:bg-slate-100;
}

:where(.css-dev-only-do-not-override-1rqnfsa).ant-card-bordered {
  @apply border-0;
}

.custom-dot {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #ccc;
  margin: 0 4px;
  transition: background 0.3s;
}
.team-dot {
  background: #f3f7ff;
}

.slick-dots li.slick-active .custom-dot {
  background: #150c5b;
}

.service-provided {
  position: relative;
}

.service-provided::after {
  content: '';
  position: absolute;
  top: 100%;
  transform: translateY(-40%);
  border-radius: 10px;
  border: 3px solid #314ed9; /* Adjust the border width and color as needed */
  height: 0px; /* Adjust the height */
  width: 150px; /* Adjust the width */
}

.use-cases .slick-track {
  @apply !h-full;
}
.use-cases .slick-slider .slick-list {
  @apply !h-full;
}
.use-cases .slick-track .slick-slide > div:first-child {
  @apply !h-full;
}
